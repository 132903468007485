import api from "@src/api/index.js";

const redirects = {
  isRequired: () => {
    throw new Error("Session es requerido");
  },

  isCrawler: false,

  redirectTo: (location = null) => {
    return {
      redirect: {
        permanent: false,
        destination: location ? location : "/login",
      },
    };
  },

  redirectToNotFound: () => {
    return {
      redirect: {
        permanent: false,
        destination: location ? location : "/404",
      },
    };
  },

  guest: () => {
    if (!redirects.isCrawler) return redirects.redirectTo("/home");
  },

  auth: () => {
    if (!redirects.isCrawler) return redirects.redirectTo("/login");
  },

  toEvent: (status, url = null, url2 = null, hasDiploma = null) => {
    if (!redirects.isCrawler)
      return redirects.redirectTo(
        `/login/?status=${status}${url ? `&url=${url}` : ""}${
          url2 ? `&url2=${url2}` : ""
        }${hasDiploma ? `&hasDiploma=${hasDiploma}` : ""}`
      );
  },

  protected: () => {
    if (!redirects.isCrawler) return redirects.redirectTo("prohibido");
  },

  chat: (session, role) => {
    //console.log(  Number(session.user.image.role) === role  )
    if (!redirects.isCrawler)
      return Number(session.user.image.role) === role ||
        Number(session.user.image.role) === 5
        ? true
        : false;
  },

  isAdmin: (session) => {
    if (!redirects.isCrawler)
      return Number(session.user.image.role) >= 5 ? true : false;
  },

  canVisit: async (session) => {
    const user = await (
      new api.GetUserCountry(session.user.image.user_id) as any
    ).doQuery();

    const {
      country: { id },
    } = user;

    const countries = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

    if (countries.includes(Number(id))) {
      return true;
    }

    if (!redirects.isCrawler) {
      return (session.user.image.license_verified === "null" ||
        session.user.image.license_verified) === "false"
        ? false
        : true;
    }
  },
};

export default redirects;
